import React from "react";
import { useSelector } from "react-redux";
const Objection = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="bg-yellow">
                <div className="container" data-aos="fade-right">
                    <div className="row">
                        <div className="col-10">
                            <h2>{data.about.objection.title}</h2>
                            <div className="container_grid">
                                {
                                    data.about.objection.description.map((obj, k) => {
                                        
                                        return (
                                            <div className={"tgrre tgrre_"+ k} key={k}>
                                                    <div className="border-top-left mb-70 ">
                                                        <p className="description-section"
                                                            key={k}
                                                            dangerouslySetInnerHTML={{
                                                                __html: obj.paragraph,
                                                            }}
                                                        ></p>
                                                </div>
                                            </div>
                                        );
                                    })
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Objection;