import React from "react";
import { useSelector } from "react-redux";
const Terms = () => {
  const data = useSelector((state) => state.lang.data);
  return (
    <>
      <div className="page page__contact" >

        <div className="container section__padding">
          <div className="row mb-md-5 justify-content-center">
            <div className="col-12 text-left">
              <h3 data-aos="fade-up">{data.terms_and_conditions.title}</h3>
              <p data-aos="fade-up">{data.terms_and_conditions.description1}</p>
              <p data-aos="fade-up">{data.terms_and_conditions.description2}</p>
              {
                data.terms_and_conditions.content.map((value, i) => {
                  return (
                    <>
                      <h5 key={i} data-aos="fade-up">{value.title}</h5>

                      {
                        value.terms.map((item, j) => {
                          return (
                       
                              <p 
                              data-aos="fade-up"
                              key={j}
                              dangerouslySetInnerHTML={{
                                __html: item.text,
                              }}
                              ></p>
                          );
                        })
                      }
                    </>
                  );
                })
              }
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms;

