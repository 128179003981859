import React from "react";
import { useSelector } from "react-redux";
const OperationalGoods = () => {
  const data = useSelector((state) => state.lang.data);
  return (
    <>
      <div className="operational__goods" data-aos="fade-up">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-11 col-md-4 mb-lg-5">
              <h3 style={{ color: " #d2bd48  "}}>{data.key_operating_assets.section1.silo_1.title}</h3>
              <p>{data.key_operating_assets.section1.silo_1.capacity}</p>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-3 col-md-4">
              <div className="row">
                <div className="col-12 mb-3 mb-md-5">
                  <h3 style={{ color: " #606644  "}}>{data.key_operating_assets.section1.silo_2.title}</h3>
                  <p>{data.key_operating_assets.section1.silo_2.capacity}</p>
                </div>
                <div className="col-12">

                  <h3 style={{ color: " #8ba281  "}}>{data.key_operating_assets.section1.silo_3.title}</h3>
                  <p>{data.key_operating_assets.section1.silo_3.capacity}</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 paddings__mobile">
              <div className="capacity-box">
                <div className="position-capacity-box">
                  <p>{data.key_operating_assets.section1.capacity_box.sub_title}</p>
                  <h3>{data.key_operating_assets.section1.capacity_box.title}</h3>
                  <p className="brief">{data.key_operating_assets.section1.capacity_box.text}</p>
                </div>
              </div>
            </div>
            <div className="col-3 col-md-4">
              <div className="row">
                <div className="col-12 mb-3 mb-md-5">
                  <h3 style={{ color: " #c1943c  "}}>{data.key_operating_assets.section1.silo_4.title}</h3>
                  <p>{data.key_operating_assets.section1.silo_4.capacity}</p>
                </div>
                <div className="col-12">
                  <h3 style={{ color: " #c3b54a  "}}>{data.key_operating_assets.section1.silo_5.title}</h3>
                  <p>{data.key_operating_assets.section1.silo_5.capacity}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 col-md-4 mt-lg-5">
              <h3 style={{ color: " #a6a163  "}}>{data.key_operating_assets.section1.silo_6.title}</h3>
              <p>{data.key_operating_assets.section1.silo_6.capacity}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OperationalGoods;
