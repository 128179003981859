import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import "./Contact.css";
const Contact = () => {
    const data = useSelector((state) => state.lang.data);
    return (
    <>
        <div className="page page__contact" >
            <Hero
            bgUrl={"/assets/images/220829/bg_contact.jpg"}
            h1White={`${data.contact.title1}`}
            />
            <div className="container section__padding">
                <div className="row mb-md-5 justify-content-center contact_description">
                    <div className="col-11 col-md-10 text-center" data-aos="fade-up">
                        <h2>{data.contact.section1.title}</h2>
                        <p>{data.contact.section1.description}</p>
                    </div>
                </div>
                <div className="row justify-content-center text-center contact_description">
                    {
                        data.contact.section1.contacts.map((item, i) => {
                            return (
                                <div className="col-11 col-md-4" key={i} data-aos="fade-up">
                                    <div className="icons__contact"> <img src={item.imgUrl} className="icon-contact" alt={item.title} /> </div>
                                    <p><b className="ct__title">{item.title}</b><br/>   <a href={item.link}>{item.link_text}</a> <br/> <a href={item.link2}>{item.link2_text}</a> </p>
                                </div>
                            );
                        })
                    } 
                </div>
            </div>
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11387.251532740152!2d26.1046826!3d44.4780081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbeced3cc2e88c06a!2sIntercereal!5e0!3m2!1sro!2sro!4v1648625707266!5m2!1sro!2sro"
                    width="100%"
                    height="500"
                    style={{border: "0"}}
                    allowFullScreen=""
                    loading="lazy"
                    title="contact-iframe-map"
                    referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    </>
    );
};
export default Contact;