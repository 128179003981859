//React Imports

//Hooks Imports
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components Imports
import Hero from "../../../components/UI/Hero/Hero";

//CSS Imports
import classes from "./SinglePost.module.css"

const SinglePost = () => {
    const params = useParams();
    const data = useSelector(state => state.lang.data);

    const postId = params.postId;

    const createPostBody = () => {
        var postBody = [];
        for(const key in data.posts[postId].content){
            var aux;
            switch (key.substring(0,2)) {
                case "h3":
                    aux = <h3 data-aos="fade-up" key={key}>{data.posts[postId].content[key]}</h3>;
                    break;
                case "h2":
                    aux = <h2 data-aos="fade-up" key={key}>{data.posts[postId].content[key]}</h2>;
                    break;
                default: 
                    aux = <p data-aos="fade-up" key={key}>{data.posts[postId].content[key]}</p>
            }
            postBody.push(aux);
        }

        return postBody;
    }

    const postBody = createPostBody();

    return(
    <div className={`page ${classes.page_post}`}>
      <Hero
        bgUrl={data.posts[postId].bgUrl}
        h1White={data.posts[postId].title}
      />
      <div className="container section__padding page_post_padding">
        <div className="row align-items-end">
          <div className="col-12">
            {postBody}
            <h3 data-aos="fade-up" className={classes.source}>Sursa: <a href={data.posts[postId].byUrl} >{data.posts[postId].by}</a></h3>
          </div>
        </div>
      </div>
    </div>
    )
}
export default SinglePost;