//React Imports
import { useSelector } from "react-redux";

//CSS Imports
import "./Footer.css";

const Footer = (props) => {
  const data = useSelector((state) => state.lang.data);
  // const mapRef = useRef(null);

  // useEffect(()=>{
  //   if(mapRef && mapRef.current) {
  //     mapRef.current.addEventListener('touchstart', null, { passive: true });
  //     mapRef.current.addEventListener('touchmove', null, { passive: true });
  //     console.log("Added event listeners");

  //     return () => {
  //       mapRef.current.removeEventListener('touchstart', null);
  //       mapRef.current.removeEventListener('touchmove', null);
  //     }
  //   }
  // },[])

  // const handler = (e) => {
  //   // e.preventDefault();
  // }


  return (
    <>
      <div className="footer footer1">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12 ">
              <img
                className="footer-logo"
                src="/assets/images/logo.png"
                alt="Logo"
              />
              <span className="description-footer">
                {data.footer.description}
              </span>
            </div>
            <div className="col-lg-2 col-sm-12 footer-info">
              <h3>{data.footer.company}</h3>
              <div className="list-company">
                <a className="link-footer" href="/">
                  {data.footer.home}
                </a>
                <a className="link-footer" href="/about">
                  {data.footer.about}
                </a>
                <a className="link-footer" href="/team">
                {data.footer.team}
                </a>
                <a className="link-footer" href="/media">
                  {data.footer.media}
                </a>
                
                <a className="link-footer" href="/contact">
                  {data.footer.contact}
                </a>
                <a className="link-footer" href="/terms">
                  {data.footer.terms}
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 footer-info">
              <h3>{data.footer.farm}</h3>
              <div className="list-company">
                <a className="link-footer" href="/farm-organization">
                  {data.footer.farm_organization}
                </a>
                <a className="link-footer" href="/key-operating-assets">
                  {data.footer.assets}
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11387.251532740152!2d26.1046826!3d44.4780081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbeced3cc2e88c06a!2sIntercereal!5e0!3m2!1sro!2sro!4v1648625707266!5m2!1sro!2sro"
                width="100%"
                height="250"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                title="footer-iframe-map"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            {/* <div className="col-lg-3 col-sm-12 footer-newsletter">
              <h3>{data.footer.subscribe}</h3>
              <form>
                <input type="text" placeholder={data.footer.name} />
                <input type="text" placeholder={data.footer.email} />
                <div className="checkbox">
                  <input type="checkbox" />
                  <label>
                    {data.footer.accept}
                    <a
                      href="/en/general-data-protection-regulation/"
                      target="_blank"
                    >
                      {data.footer.personal}
                    </a>
                  </label>
                </div>
                <div className="box__cta__btn">
                  <a className="cta__btn" href="/about">
                    {data.footer.button}
                  </a>
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>{data.footer.copyright}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
