import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const AssetsSection = () => {
  const data = useSelector((state) => state.lang.data);
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
  };


  return (
    <>
      <div className="container section__padding key__operational effe">
        <div className="row">
          <div className="col-12 text-center" data-aos="fade-up">
            <h2> {data.home.keyAssets.title}</h2>
            <p>{data.home.keyAssets.description}</p>
          </div>
          <div className="col-12">
            <div className="row justify-content-center">

            <Slider {...settings} className="assets-carousel">
              {
                data.home.keyAssets.boxes.map((box, i) => {
                  return (
                    <div className={"col-12 col-md-6 col-lg-4 bg-col mb-4 key_box key_box_"+ i} key={i} data-aos="fade-up">
                      <div className="bg__black__op">
                        <img src={box.image} alt={box.title} />
                        <h3>{box.title}</h3>

                        {
                          box.tables.map((tb, j) => {
                            return (
                              <table className={"key_table key_table_"+ j} key={j}>
                                <tbody>

                                  {
                                    tb.rows.map((trow, k) => {
                                      return (
                                        <tr key={k}>
                                  



                                        {
                                          trow.columns.map((tcell, l) => {
                                            return (
                                              <td key={l}>
                                                {tcell.cell}
                                              </td>
                                            );
                                          })
                                        }

                              
                      
                                      
                              
                                        </tr>
                                      );
                                    })
                                  }


                            
                                </tbody>
                              </table>
                            );
                          })
                        } 

                      </div>
                    </div>
                  );
                })
              } 
              </Slider>
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="box__cta__btn" data-aos="fade-up">
              <a className="cta__btn" href={data.home.keyAssets.cta_btn_link}>
                {data.home.keyAssets.cta_btn_text}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="poim container-fluid" >
        <div className="container section__padding ct-poim">
          <div className="row align-items-center ">
            <div className="col-12 col-md-6 pe-md-5 text-left aos-init aos-animate" data-aos="fade-right">
              <h2>Proiect POIM</h2>
              <div className="box__cta__btn" data-aos="fade-up">
                <a className="cta__btn" target="_blank" href="/assets/documents/231222/4922517937.pdf">
                Află mai multe
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
};
export default AssetsSection;