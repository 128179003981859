import React from "react";
import { useSelector } from "react-redux";
const IrrigationSystems = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
        <div className="irrigation__systems section__padding position-relative mt-md-3">
            <img data-aos="fade-right" src={data.key_operating_assets.section1.imgSoy} className="icont icont3" alt={data.key_operating_assets.section2.title} />
            <img data-aos="fade-left" src={data.key_operating_assets.section1.imgCorn} className="icont icont1" alt={data.key_operating_assets.section2.title} />
            <div className="container" data-aos="fade-up">
                <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-md-10 text-center mb-md-4">
                    <h2>{data.key_operating_assets.section2.title}</h2>
                    </div>
                    {
                    data.key_operating_assets.section2.irrigation_systems.map((irrigation, i) => {
                        return (
                            <div className="col-12 col-md-6 col-lg-4 bg-col mb-md-4" key={i}>
                                <div className="bg__black__op">
                                    <img src={irrigation.image} alt={irrigation.title} />
                                    <h5>{irrigation.title}</h5>
                                    <p>{data.key_operating_assets.section2.title_loc}: {irrigation.location}</p>
                                    <p>{data.key_operating_assets.section2.title_area}: {irrigation.surface}</p>
                                </div>
                            </div>
                        );
                    })
                    } 
                </div>
            </div>
        </div> 
        </>
    );
};
export default IrrigationSystems;