import React from "react";
import { useSelector } from "react-redux";
const Mission = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="bg-green">
                <div className="container" data-aos="fade-left">
                    <div className="row justify-content-end">
                        <div className="col-10">
                            <h2>{data.about.mission.title}</h2>
                                {
                                    data.about.mission.description.map((miss, j) => { 
                                        return ( 
                                            <p key={j}>
                                                {miss.paragraph}
                                            </p>
                                        ); 
                                    }) 
                                }  
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Mission;