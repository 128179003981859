
//React Imports
import { createSlice } from "@reduxjs/toolkit";

//Language Packages Imports
import EN from "../translations/en";
import RO from "../translations/ro";

const initialLangState = { language: "ro", data: { ...RO } };

const langSlice = createSlice({
  name: "lang",
  initialState: initialLangState,
  reducers: {
    en(state) {
      state.language = "en";
      state.data = { ...EN };
      return state;
    },
    ro(state) {
      state.language = "ro";
      state.data = { ...RO };
      return state;
    },
  },
});

export const langActions = langSlice.actions;

export default langSlice.reducer;
