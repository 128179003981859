const Hero = (props) => {
  const pTextStyle = props.pTextStyle
    ? `hero-description ${props.pTextStyle}`
    : "hero-description";

  const backgroundOpacity = props.bgOpacity ? props.bgOpacity : "bg-black2-opacity";

  return (
    <div
      className="hero-image-page"
      style={{ backgroundImage: `url(${props.bgUrl})` }}
    >
      <div className={backgroundOpacity}>
        <div className="container" style={{ position: "relative" }}>
          <div className="hero-content">
            <h1 data-aos="fade-up">
              {props.h1White}
              <span style={{ color: `var(--green)` }}> {props.h1Green}</span>
            </h1>
              <div className={pTextStyle} style={{ fontWeight: "500" }} data-aos="fade-up">
                {props.pText}
                <br /> {props.pNewLine}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
