import React from "react";
import { useSelector } from "react-redux";
const AboutSection = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="container section__padding about__us" data-aos="fade-down">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <h2>{data.home.aboutSection.title}</h2>
                        <p>{data.home.aboutSection.description}</p>
                    </div>
                    <div className="col-12">
                        <div className="box__cta__btn">
                            <a className="cta__btn" href={data.home.aboutSection.cta_btn_link}> {data.home.aboutSection.cta_btn_text}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AboutSection;