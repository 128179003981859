import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import "./Team.css";
const Team = () => {
    const data = useSelector((state) => state.lang.data);
    return (
    <>
    <div className="page page__Team">
        <Hero
        bgUrl={"/assets/images/220829/bg_team.jpg"}
        h1White={`${data.team.heroSection.title1}`}
        h1Green={`${data.team.heroSection.title2}`}
        />
        <div className="container section__padding pb-0 text-center">
            <div className="row justify-content-center mb-4 mb-md-5">
                <div className="col-11 col-md-11" data-aos="fade-up">
                    <p>{data.team.intro.description} </p>
                </div>
            </div>
        </div>
        <div className="container-fluid text-center section-team">
            <div className="row justify-content-center align-items-center">
                {
                    data.team.members.map((member, i) => {
                        return (
                            <div className="col-12 col-md-4 col-member" key={i}>
                                <div className="row">
                                    <div  className="col-12 px-0" style={{ position: "relative" }}>
                                        <img className="img_hero" alt={member.title} src={`${member.image}`} style={{ width: "100%" }}  />
                                        <div className="triangle"></div>
                                    </div>
                                    <div  className="col-12 px-0">
                                        <div className="box-team" data-aos="fade-up">
                                            <h3 className="name">{member.title}</h3>
                                            <p className="position">{member.role}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                } 
            </div>
        </div>
    </div>
    </>
    );
};
export default Team;