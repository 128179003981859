import React from "react";
import HeroSection from "../../components/UI/HeroHome/HeroSection";
import AboutSection from "./Components/AboutSection/AboutSection";
import FarmOrganizationSection from "./Components/FarmOrganizationSection/FarmOrganizationSection";
import AssetsSection from "./Components/AssetsSection/AssetsSection";
import "./Home.css";
const Home = () => {
  return (
    <>
      <div className="home-page">
        <HeroSection />
        <AboutSection />
        <FarmOrganizationSection />
        <AssetsSection />
      </div>
    </>
  );
};
export default Home;