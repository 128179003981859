// src/app/Main.jsx

//React Imports
import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { langActions } from "./store/language";

// Import CSS
import "./styles/base.css";
// import BeatLoader from "react-spinners/BeatLoader";
// import LoadingSpinner from "./components/UI/LoadingSpinner/LoadingSpinner";

// Import pages
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import KeyOperatingAssets from "./pages/KeyOperatingAssets/KeyOperatingAssets";
import FarmOrganization from "./pages/Farm-Organization/FarmOrganization";
import Crops from "./pages/Crops/Crops";
import Contact from "./pages/Contact/Contact";
import Media from "./pages/Media/Media";
import SinglePost from "./pages/Media/SinglePost/SinglePost";
import Terms from "./pages/Terms/Terms";
import Error404 from "./pages/404/Error404";

// Import Layout
import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";
import MenuModal from "./components/UI/MenuModal/MenuModal";
import Farms from "./pages/Farms/Farms";
import Team from "./pages/Team/Team";

import Aos from "aos";
import "aos/dist/aos.css";
// Component Implementation
const Main = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const override: CSSProperties = {
  //   display: "block",
  //   margin: "0 auto",
  //   borderColor: "red",
  // };
  // let [loading, setLoading] = useState(false);  

  const dispatch = useDispatch();

  const showMenuModalHandler = () => {
    setModalIsOpen(true);
  };

  const hideMenuModalHandler = () => {
    setModalIsOpen(false);
  };

  useEffect(()=>{
    // setLoading(true)
    // setTimeout(() => {
    //   setLoading(false)
    // }, 100);

    Aos.init({duration: 1000});

    const langUpdater = localStorage.getItem('persist:root');
    if(langUpdater){
      let langData = JSON.parse(langUpdater);
      langData = JSON.parse(langData.lang)
      if(langData.language === "ro"){
        dispatch(langActions.ro());
      }else{
        dispatch(langActions.en());
      }
    }
  },[dispatch]);

  

  return (
    <div className="App">
       <React.Fragment>
          <MenuModal showModal={modalIsOpen} hideMenuModal={hideMenuModalHandler} />
          <Header showMenuModal={showMenuModalHandler} />
          <Routes>
            <Route exact={true} path="/" element={<Home />} />
            <Route exact={true} path="/about" element={<About />} />
            <Route exact={true} path="/key-operating-assets" element={<KeyOperatingAssets />} />
            <Route
              exact={true}
              path="/farm-organization"
              element={<FarmOrganization />}
            />
            <Route exact={true} path="/farm-organization/crops" element={<Crops />} />
            <Route exact={true} path="/farm-organization/farms" element={<Farms />} />
            <Route exact={true} path="/team" element={<Team />} />
            <Route exact={true} path="/contact" element={<Contact />} />
            <Route exact={true} path="/media" element={<Media />} />
            <Route exact={true} path="/media/:postId" element={<SinglePost />} />
            <Route exact={true} path="/terms" element={<Terms />} />
            <Route path="*" element={<Error404 />}></Route>
          </Routes>
          <Footer />
        </React.Fragment>

    </div>



  );
};

export default Main;
