import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import "./FarmOrganization.css";
const FarmOrganization = () => {
  const data = useSelector((state) => state.lang.data);  
  return (
    <>
      <div className="page page_farm_organization ">
        <Hero
          bgUrl={"/assets/images/220829/bg_farm_organization.jpg"}
          h1White={data.farm_organization.heroSection.title1}
          h1Green={data.farm_organization.heroSection.title2}
          pText={data.farm_organization.heroSection.description}
          pNewLine={data.farm_organization.heroSection.description_newline}
        />
        <div className="container">
          {
            data.farm_organization.details.map((value, i) => {
              return (
                <div className={"row row_crops_and_farms align-items-center justify-content-center section__padding pe-5"} key={i} >
                  <div className="col-11 col-md-6" data-aos="fade-right">
                    <h2>{value.title}</h2>
                    <p style={{ fontWeight: "300" }}>{value.description}</p>
                    <img className="img_hero d-block d-md-none my-3" data-aos="fade-up" alt={value.title} src={value.img} style={{ width: "100%", borderRadius: "15px" }}  />
                    <div className="box__cta__btn ">
                      <a className="cta__btn" href={value.cta_btn_link}> {value.cta_btn_text} </a>
                    </div>
                  </div>
                  <div className="col-11 col-md-6  d-none d-md-block" data-aos="fade-up">
                    <img className="img_hero" alt={value.title} src={value.img} style={{ width: "100%", borderRadius: "15px" }}  />
                  </div>                     
                </div>
              );
            })
          } 
        </div>
      </div>
    </>
  );
};
export default FarmOrganization;