
//React Imports
import { useSelector } from "react-redux";

//Components Imports
import MediaPost from "./MediaPost"

const MediaPosts = () => {
    const data = useSelector(state => state.lang.data);

    const posts = Object.keys(data.posts).map(key => [key, data.posts[key]]);


 return(
     <>
     {posts.map((post, i)  => <MediaPost key={i} id={post[0]} imgUrl={post[1].imgUrl} title={post[1].title} by={post[1].by} byUrl={post[1].byUrl} description={post[1].description} button={data.media.button} />)}
     </>
 )   
}

export default MediaPosts;