import classes from "./Backdrop.module.css";

const Backdrop = (props) => {
  const cssBackdrop = [
    classes.backdrop,
    props.show ? classes.backdrop_open : classes.backdrop_closed,
  ];

  return <div className={cssBackdrop.join(" ")}></div>;
};

export default Backdrop;
