import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import Mission from "./Components/Mission/Mission";
import Objection from "./Components/Objection/Objection";
import Values from "./Components/Values/Values";
import "./About.css";
const About = () => {
  const data = useSelector((state) => state.lang.data);
  return (
    <>
      <div className="page page_about">
        <Hero
          bgUrl={"/assets/images/220829/bg_about.jpg"}
          h1White={`${data.about.heroSection.title1}`}
          h1Green={`${data.about.heroSection.title2}`}
          pText={`${data.about.heroSection.description}`}
          pNewLine={`${data.about.heroSection.description_newline}`}
        />
        <div className="intro__about">
          <div>
            <div className="container section__padding pb-0">
              <div className="row align-items text-center justify-content-center">
                <div className="col-11 col-md-11 mb-50">
                  <h2 data-aos="fade-up">{data.about.intro.title}</h2>
                </div>
                <div className="col-11 mb-50">
                  {
                    data.about.intro.description.map((value, i) => {
                        return (
                          <p className="description-section"
                          data-aos="fade-up"
                          key={i}
                          dangerouslySetInnerHTML={{
                            __html: value.paragraph,
                          }}
                          ></p>
                        );
                    })
                  } 
                </div>
                <div className="col-11 col-md-12 no-paddings-mobile">
                  <img className="img_hero" alt={data.about.intro.title} src={data.about.intro.image} style={{ width: "60%" }}  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Mission />
        <Objection />
        <div className="container pt-5 pt-md-4 pb-0">
          <div className="row align-items-center">
            <div className="col-11 col-md-6 pe-md-5 text-center-mb" data-aos="fade-right">
              <h2>{data.about.ic_about.title}</h2>
              <p>{data.about.ic_about.description}</p>
              <img alt={data.about.ic_about.title} className="img_hero d-block d-md-none" src={data.about.ic_about.image} style={{ width: "100%", borderRadius: "15px", margin: "30px 0" }} />
              <div className="box__cta__btn">
                <a className="cta__btn" href={data.about.ic_about.cta_btn_link}> {data.about.ic_about.cta_btn_text}</a>
              </div>
            </div>
            <div className="col-11 col-md-6 pe-md-5 text-center-mb d-none d-md-block" data-aos="fade-up">
              <img alt={data.about.ic_about.title} className="img_hero" src={data.about.ic_about.image} style={{ width: "100%", borderRadius: "15px" }}  />
            </div>
          </div>
        </div>
        <Values />
      </div>
    </>
  );
};
export default About;