
//React Imports
import { Navbar, Container, Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//Language Hooks Imports
import { langActions } from "../../../../store/language";

//Icons Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

//CSS Imports
import classes from "./MenuOverlay.module.css";

const MenuOverlay = (props) => {
  const data = useSelector((state) => state.lang.data);
  const language = useSelector((state) => state.lang.language);
  const dispatch = useDispatch();

  const cssModal = [
    classes.modal,
    props.show ? classes.modal_open : classes.modal_closed,
  ];

  const changeLanguageENHandler = () => {
    dispatch(langActions.en());
  };

  const changeLanguageROHandler = () => {
    dispatch(langActions.ro());
  };

  const onClickHandler = () => {
    props.hideMenuModal();
  };

  return (
    <>
      <div className={cssModal.join(" ")}>
        <div className={classes.content}>
          <Container className="flex-lg-column">
            <Navbar.Toggle
              onClick={onClickHandler}
              className={classes.button_navbar}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className={classes.fa_times_dim}
                inverse
              />
            </Navbar.Toggle>
            <Navbar.Brand href="/" className="d-flex d-lg-none">
              <img
                className={classes.logo}
                src="/assets/images/logo.png"
                alt="Logo"
                width="130px"
              />
            </Navbar.Brand>
            <Navbar.Brand href="/" className="d-none d-lg-flex me-auto">
              <img src="/assets/images/logo.png" alt="Logo" width="130px" />
            </Navbar.Brand>
            <Nav
              className={`align-center ${classes.flex_col} ${classes.modal_menu}`}
            >
              <Nav.Link className={classes.a_link} href="/about">
                {data.nav.about}
              </Nav.Link>
              <Nav.Link className={classes.a_link} href="/farm-organization">
                {data.nav.farm_organization}
              </Nav.Link>
              <Nav.Link className={classes.a_link} href="/key-operating-assets">
                {data.nav.key_operating_assets}
              </Nav.Link>
              <Nav.Link className={classes.a_link} href="/team">
                {data.nav.team}
              </Nav.Link>
              <Nav.Link className={classes.a_link} href="/media">
                {data.nav.media}
              </Nav.Link>
              <Nav.Link className={classes.a_link} href="/contact">
                {data.nav.contact}
              </Nav.Link>
            </Nav>
            <Nav className={`me-auto ${classes.flex_col}`}>
              <Nav.Link
                href="tel:+40 21 318 07 72"
                className={`${classes.a_link} nav-link-row-1-left`}
              >
                <img
                  src="/assets/images/phone.png"
                  alt="Phone"
                  width="23px"
                  style={{ marginRight: "15px" }}
                />
                +40 21 318 07 72
              </Nav.Link>
              <Nav.Link
                href="mailto:office@intercereal.ro"
                className={`${classes.a_link} nav-link-row-1-left`}
              >
                <img
                  src="/assets/images/email.png"
                  alt="Email"
                  width="23px"
                  style={{ marginRight: "15px" }}
                />
                office@intercereal.ro
              </Nav.Link>
            </Nav>
            <Nav className="navbar-social">
              <Nav.Link
                href="https://www.facebook.com/Intercereal-100152312393099/"
                target="_blank"
                className="nav-link-row-1-right"
              >
                <FontAwesomeIcon icon={faFacebookF} inverse />
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/intercereal/"
                target="_blank"
                className="nav-link-row-1-right"
              >
                <FontAwesomeIcon icon={faInstagram} inverse />
              </Nav.Link>
              <Nav.Link
                className={language === "en" ? "link-active" : "link"}
                onClick={changeLanguageENHandler}
                data-language="en"
              >
                <img
                  className="icon-flag"
                  src="/assets/images/en.png"
                  alt="English Language"
                  width="24px"
                />
              </Nav.Link>
              <Nav.Link
                className={
                  language === "ro" ? "link-active link_ro" : "link link_ro"
                }
                onClick={changeLanguageROHandler}
                data-language="ro"
              >
                <img
                  className="icon-flag"
                  src="/assets/images/ro.png"
                  alt="Limba Romana"
                  width="24px"
                />
              </Nav.Link>
            </Nav>
          </Container>
        </div>
      </div>
    </>
  );
};

export default MenuOverlay;
