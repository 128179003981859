import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
const FarmsCarousel = () => {
    var settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="crops__section text-center">
                <div style={{ backgroundColor: "rgba(27, 61, 47, 0.9)" }}>
                    <div className="container section__padding" style={{ paddingTop: "50px", paddingBottom: "30px" }}>
                        <div className="row" style={{ justifyContent: "space-between" }}>
                            <Slider {...settings} className="assets-carousel">
                                {
                                    data.farm.surface_seed.map((value, i) => {
                                        return (
                                            <div className="mb-4 about-seeds" key={i} data-aos="fade-up">
                                                <h3>{value.title}</h3>
                                                <p className="crop__surface">{value.surface} <span>(Ha)</span></p>
                                            </div>
                                        );
                                    })
                                } 
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FarmsCarousel;