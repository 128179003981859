import React from "react";
import { useSelector } from "react-redux";
const SeedMachines = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="seed__conditioning__machines">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-11 col-md-9 text-center mb-md-4" data-aos="fade-up">
                            <h2>{data.key_operating_assets.section3.title}</h2>
                            <p>{data.key_operating_assets.section3.description}</p>
                        </div>
                    </div>
                </div>
                <img
                className="img_hero"
                alt={data.key_operating_assets.section3.title}
                src={data.key_operating_assets.section3.imgUrl}
                style={{ width: "100%", objectFit: "cover", objectPosition: "50% 50%" }} 
                />
            </div>
        </>
    );
};
export default SeedMachines;