import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import FarmsCarousel from "./Components/FarmsCarousel/FarmsCarousel";
import Farm from "./Components/Farm/Farm";
import "./Farms.css";
const Farms = () => {
  const data = useSelector((state) => state.lang.data);
  return (
    <>
      <div className="page page_farm ">
        <Hero
          bgUrl={"/assets/images/220829/bg_farms.jpg"}
          h1White={data.farm.heroSection.title1}
          h1Green={data.farm.heroSection.title2}
          pText={data.farm.heroSection.description}
          pNewLine={data.farm.heroSection.description_newline}
        />
        <FarmsCarousel />
        <div className="container text-center ">
          <div className="section__padding bg-white pb-0">
            <div className="row justify-content-center">
              <div className="col-11">
                <h2 data-aos="fade-up">{data.farm.intro.title}</h2>
              </div>
              <div className="col-12 col-md-8 mb-2 mb-md-4 position-relative">
                <p data-aos="fade-up" className="description-section">{data.farm.intro.description}</p>
              </div>
            </div>
          </div>
        </div>
        <Farm />
      </div>
    </>
  );
};
export default Farms;