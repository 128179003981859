import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import OperationalGoods from "./Components/OperationalGoods/OperationalGoods";
import IrrigationSystems from "./Components/IrrigationSystems/IrrigationSystems";
import SeedMachines from "./Components/SeedMachines/SeedMachines";
import "./KeyOperatingAssets.css";
const KeyOperatingAssets = () => {
  const data = useSelector((state) => state.lang.data);
  return (
    <>
      <div className="page page__key">
        <Hero
          bgUrl={"/assets/images/220829/bg_active.jpg"}
          h1White={`${data.key_operating_assets.heroSection.title1}`}
          h1Green={`${data.key_operating_assets.heroSection.title2}`}
          pText={`${data.key_operating_assets.heroSection.description}`}
          pNewLine={`${data.key_operating_assets.heroSection.description_newline}`}
        />
        <div className="section__padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-11 col-md-10 text-center mb-md-4" data-aos="fade-up">
                <h2>{data.key_operating_assets.section1.title}</h2>
                <p>{data.key_operating_assets.section1.description}</p>
              </div>
            </div>
          </div>
          <OperationalGoods />
        </div>
        <IrrigationSystems />
        <SeedMachines />
      </div>
    </>
  );
};
export default KeyOperatingAssets;