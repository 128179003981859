// src/app/components/Header.js
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';

//Import Components
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { langActions } from "../../../store/language";

//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

// Import CSS
import "../../../styles/components/header.css";
import "../../../styles/components/links.css";

const Header = (props) => {
  const data = useSelector((state) => state.lang.data);
  const language = useSelector((state) => state.lang.language);
  const dispatch = useDispatch();

  const changeLanguageENHandler = () => {
    dispatch(langActions.en());
  };

  const changeLanguageROHandler = () => {
    dispatch(langActions.ro());
  };

  const onClickHandler = () => {
    props.showMenuModal();
  };










  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }


  const {pathname} = useLocation();




  return (
    
    <header className="header">


      <Navbar collapseOnSelect expand="lg" bg="black" expanded={false}>
        <Container className="flex-lg-column">
          <Navbar.Toggle onClick={onClickHandler}>
            <FontAwesomeIcon icon={faAlignJustify} inverse />
          </Navbar.Toggle>
          <Navbar.Brand href="/" className="d-flex d-lg-none">
            <img
              className="header_logo"
              src="/assets/images/logo.png"
              alt="Intercereal"
              width="130px"
            />
          </Navbar.Brand>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="w-100 order-lg-2 navbar-nav-row-2"
          >
            <Navbar.Brand href="/" className="d-none d-lg-flex me-auto">
              <img src="/assets/images/logo.png" alt="Intercereal" width="130px" />
            </Navbar.Brand>
            <Nav className="align-center">
              <Nav.Link className={(pathname === '/about') ? 'active' : ''} href="/about">{data.nav.about}</Nav.Link>



     
              <NavDropdown title={data.nav.farm_organization}
                show={show}
                onMouseEnter={showDropdown} 
                onMouseLeave={hideDropdown}
                href="/farm-organization"
                className={(pathname === "/farm-organization/crops" || pathname === "/farm-organization/farms") ? 'ic-ropdown-item nav-link active' : 'nav-link'} 
                style={{padding: "0"}}

                
                >
                  <NavDropdown.Item 
                    href="/farm-organization"
                    className={(pathname === '/farm-organization') ? 'd-none active' : 'd-none'} 
                  >{data.nav.farm_organization}</NavDropdown.Item>

                    <NavDropdown.Item 
                      href="/farm-organization/crops"
                      className={(pathname === '/farm-organization/crops') ? 'nav-link active' : ''} 
                    >{data.nav.crop}</NavDropdown.Item>
                    <NavDropdown.Item 
                      href="/farm-organization/farms"
                      className={(pathname === '/farm-organization/farms') ? 'nav-link active' : ''} 
                    >{data.nav.farm}</NavDropdown.Item>
                </NavDropdown>

              <Nav.Link className={(pathname === '/key-operating-assets') ? 'active' : ''} href="/key-operating-assets">{data.nav.key_operating_assets}</Nav.Link>
              <Nav.Link className={(pathname === '/contact') ? 'active navbar-contact' : 'navbar-contact'} href="/contact">
                {data.nav.contact}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="w-100 order-md-1 navbar-nav-row-1"
          >
            <Nav className="me-auto">
              <Nav.Link
                href="tel:+40 21 318 07 72"
                className="nav-link-row-1-left"
              >
                <img src="/assets/images/phone.png" alt="Phone" width="23px" />{" "}
                +40 21 318 07 72
              </Nav.Link>
              <Nav.Link
                href="mailto:office@intercereal.ro"
                className="nav-link-row-1-left"
              >
                <img src="/assets/images/email.png" alt="Email" width="23px" />
                office@intercereal.ro
              </Nav.Link>
            </Nav>
            <Nav className="navbar-social">
              <Nav.Link
                href="https://www.facebook.com/Intercereal-100152312393099/"
                target="_blank"
                className="nav-link-row-1-right"
              >
                {/* <img
                    src="/assets/images/facebook.svg"
                    alt="Facebook"
                    width="17px"
                  /> */}
                <FontAwesomeIcon icon={faFacebookF} />
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/intercereal/"
                target="_blank"
                className="nav-link-row-1-right"
              >
                {/* <img
                    src="/assets/images/instagram.svg"
                    alt="Instagram"
                    width="18px"
                  /> */}
                <FontAwesomeIcon icon={faInstagram} inverse />
              </Nav.Link>

           
              <Nav.Link
                className={language === "ro" ? "link-active" : "link"}
                onClick={changeLanguageROHandler}
                data-language="ro"
              >
                <img
                  className="icon-flag"
                  src="/assets/images/ro.png"
                  alt="Limba Romana"
                  width="24px"
                />
              </Nav.Link>

              <Nav.Link
                className={language === "en" ? "link-active" : "link"}
                onClick={changeLanguageENHandler}
                data-language="en"
              >
                <img
                  className="icon-flag"
                  src="/assets/images/en.png"
                  alt="English"
                  width="24px"
                />
              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
