import { useSelector } from "react-redux";

import "./Error404.css";

const Error404 = () => {
  const data = useSelector((state) => state.lang.data);

  return (
    <>
      <div className="page" style={{ background: `#f0f0f0` }}>
        <div
          className="container container__page__error"
          style={{ position: `relative` }}
        >
          <div className="hero-content">
            <h1>
              {data.error.title1}
              <span style={{ color: `var(--green)` }}>{data.error.title2}</span>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
