import React from "react";
import { useSelector } from "react-redux";
import Hero from "../../components/UI/Hero/Hero";
import "./Crops.css";
import Crop from "./Components/Crop/Crop";
import CropsCapacity from "./Components/CropsCapacity/CropsCapacity";
const Crops = () => {
  const data = useSelector((state) => state.lang.data);
  return (
    <>
      <div className="page page_crops ">
        <Hero
          bgUrl={"/assets/images/220829/bg_crops.jpg"}
          h1White={data.crops.heroSection.title1}
          h1Green={data.crops.heroSection.title2}
          pText={data.crops.heroSection.description}
          pNewLine={data.crops.heroSection.description_newline}
        />
        <CropsCapacity />
        <div className="container-fluid" style={{ padding: "0" }}>
          <div className="container section__padding" data-aos="fade-right">
              <div className="row justify-content-center">
                <div className="col-11 col-md-12 text-center">
                  <p className="description-section" style={{ fontWeight: "300" }}>{data.crops.description}</p>
                </div>
            </div>
          </div>
          <Crop />
        </div>
      </div>
    </>
  );
};
export default Crops;
