import React from "react";
import { useSelector } from "react-redux";
const FarmOrganizationSection = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
          <div className="container-fluid  farm__organization">
            <div className="polygon__organization bg__organization"
              style={{ backgroundImage: `url(${data.home.farmOrganization.bg_polygon})` }}>
            </div>
            <div className="polygon__organization border__organization"></div>
            <div className="container" data-aos="fade-right">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <div className="box__center">
                    <h2 style={{color: "#ffffff"}}>{data.home.farmOrganization.title}</h2>
                    <p>{data.home.farmOrganization.description}</p>
                    <div className="box__cta__btn">
                      <a className="cta__btn" href={data.home.farmOrganization.cta_btn_link}> {data.home.farmOrganization.cta_btn_text} </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
}
export default FarmOrganizationSection;