import React from "react";
import ReactDom from "react-dom";

import MenuOverlay from "./Overlay/MenuOverlay";
import Backdrop from "./Backdrop/Backdrop";

const portalElement = document.getElementById("overlay");

const MenuModal = (props) => {
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop show={props.showModal}/>,
        portalElement
      )}
      {ReactDom.createPortal(
        <MenuOverlay
          show={props.showModal}
          hideMenuModal={props.hideMenuModal}
        />,
        portalElement
      )}
    </>
  );
};

export default MenuModal;
