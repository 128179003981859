import React from "react";
import { useSelector } from "react-redux";
const Farm = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="section__timeline">
                <div className="list_items">
                {
                    data.farm.details_farms.map((value, i) => {
                        return (
                            <div className={"row__height row__timeline row__timeline__"+ i} key={i} >
                                <div className="container">
                                    <div className="bg-white">
                                        <div className="row align-items-center">
                                            <div className="col-11 col-md-6 position-relative">
                                                <h3 data-aos="fade-up">{value.title}</h3>
                                                <ul>
                                                {
                                                    value.text.map((item, j) => {
                                                        return (
                                                            <li data-aos="fade-up" key={j}>{item.paragraph}</li>
                                                        );
                                                    })
                                                } 
                                                </ul>
                                            </div>
                                            <div className="icon-frunza"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                } 
                </div>
                <img
                    className="img_sol"
                    alt="Ilustration Farm"
                    src={data.farm.image_sol}
                />
            </div>
        </>
    );
};
export default Farm;