//CSS
import classes from "./Button.module.css";

const Button = (props) => {
  const buttonStyle = props.className ? props.className : "";

  return (
    <div className={classes.box_cta_btn}>
      <button
        className={`${classes.cta_btn} ${buttonStyle}`}
        type={props.type ? props.type : 'button'}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </div>
  );
};

export default Button;
