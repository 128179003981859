import React from "react";
import { useSelector } from "react-redux";
const Values = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
        <div className="container section__padding">
            <div className="row text-center justify-content-center row-values">
                <div className="col-12" data-aos="fade-up">
                    <h2>{data.about.values.title}</h2>
                </div>
                {
                    data.about.values.value_list.map((val, k) => {
                        return (
                            <div className="col-11 col-md-4" key={k} data-aos="fade-up">
                                <div className="card-about">
                                    <img className="img_hero" src={val.image} alt={val.title} />
                                    <h4>{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        );
                    })
                } 
                
                <div className="col-12">
                    <div className="box__cta__btn mt-md-5" data-aos="fade-up">
                        <a className="cta__btn" href={data.about.values.cta_btn_link}> {data.about.values.cta_btn_text} </a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
export default Values;