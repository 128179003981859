// src/app/pages/Media.js

//React Imports
import React from "react";
import { useSelector } from "react-redux";

//Components Imports
import Hero from "../../components/UI/Hero/Hero";
import MediaPosts from "./Posts/MediaPosts";

//CSS Imports
import "./Media.css"

const Media = () => {
  const data = useSelector((state) => state.lang.data);

  return (
    <div className="page page_media">
      <Hero
        bgUrl={"/assets/images/220829/bg_media.jpg"}
        h1Green={`${data.media.title}`}
      />
      <div className="container section__padding">
        <div className="row align-items-end">
          <div className="col" data-aos="fade-right">
            <h3 style={{marginBottom: "25px"}}>{data.media.titleH3}</h3>
          </div>
          <MediaPosts />
        </div>
      </div>
    </div>
  );
};

export default Media;
