
//React Imports
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


//CSS Imports
import "./HeroSection.css";

export default function HeroSection() {
  const data = useSelector((state) => state.lang.data);
  const url = "/assets/videos/33144103.mp4";

  return (
    <>
      <div
        className="hero-image position-relative"
        style={{
          background: `rgba(255,255,255,0.3)`,
        }}
      >
        <img
          src="/assets/images/220830/tractor_principal_marit.png"
          alt="Agro Inter"
          width="100%"
          className="hero-section-img"
        />

        <video muted autoPlay loop>
          <source src={url} />
        </video>

        <div className="position_absolute">
          <div className="container" style={{ position: "relative" }}>
            <div
              className="hero-content text-left"
              style={{ color: "var(--black)" }}
            >

              <h1 data-aos="fade-up">
                {data.home.heroSection.slider_1.title1} <br />
                <span style={{ color: `var(--green)` }}>
                  {data.home.heroSection.slider_1.title2}
                </span>
              </h1>
              <p
                className="hero-description "
                style={{ color: "var(--black)" }}
                data-aos="fade-up"
              >
                {data.home.heroSection.slider_1.description1}
                <br />
                {data.home.heroSection.slider_1.description2}
              </p>
              <div className="box__cta__btn" data-aos="fade-up">
                <Link to="/contact" className="cta__btn">
                  {data.home.heroSection.slider_1.cta_btn_text}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
