//Components Imports
import Button from "../../../components/UI/Button/Button";

//CSS Imports
import classes from "./MediaPost.module.css";

const MediaPost = (props) => {
  return (
      <div className={`${classes.post} ${classes.card}`}>
        <div className={classes.post_left}>
          <a href="/">
            <img src={props.imgUrl} alt="Post Img" />
          </a>
        </div>
        <div className={classes.post_right} data-aos="fade-left">
          <h3>{props.title}</h3>
          <p>
            by{" "}
            <a href={props.byUrl} style={{ color: "var(--green)" }}>
              {props.by}
            </a>{" "}
          </p>
          <p>{props.description}</p>
          <a href={`/media/${props.id}`}>
            <Button className={classes.button_media}>{props.button}</Button>{" "}
          </a>
        </div>
      </div>
  );
};

export default MediaPost;
