import React from "react";
import { useSelector } from "react-redux";
const Crop = () => {
    const data = useSelector((state) => state.lang.data);
    return (
        <>
            <div className="list_crops">
            {
                data.crops.details_crops.map((crop, i) => {
                    return (
                        <div className="ic-crop" key={i}>
                            <span className="bg__crop d-none d-md-inline-block" style={{ backgroundImage: `url(${crop.image})` }}></span>
                            <div className="container">
                            <div className="row justify-content-center justify-content-md-start">
                                <div className="col-11 col-md-6" data-aos="fade-right">
                                    <h2>{crop.title} </h2>
                                    <p>{crop.description} </p>
                                </div>
                                <div className="col-11 d-block d-md-none">
                                    <img
                                    className="img_hero" alt={crop.title} src={crop.image} style={{ width: "100%", borderRadius: "15px" }} />
                                </div>
                            </div>
                            </div>
                        </div>
                    );
                })
            } 
            </div>
        </>
    );
};
export default Crop;